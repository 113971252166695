<template>
  <div>
    <md-card-header>
      <div class="md-layout favpage-header">
        <div class="md-layout-item favpage-category-name">
          <div class="title-favpage">
            {{ categoryName === 'weeklyFaves' ? 'Weekly Faves' : categoryName === 'weeklyGames' ? 'Weekly Games' :
            categoryName }} {{ year }}
          </div>
        </div>
        <div class="md-size-55">
          <md-button title="Sort lines" class="homepage-icon md-primary md-icon-button hide-show-button" @click="sortLines">
            <i
              :class="[this.currentSort === 1 ? 'fas fa-sort' : this.currentSort === 2 ? 'fas fa-chevron-up' : 'fas fa-chevron-down']"></i>
          </md-button>
          <md-button title="Increase/decrease space" class="homepage-icon md-primary md-icon-button hide-show-button" @click="changeSpacing">
            <i class="fa fa-minus-square-o" v-if="!isMinCellsSpace" title="Minify space between cells"></i>
            <i class="fa fa-plus-square-o" v-else title="Expand space between cells"></i>
          </md-button>
          <md-button title="Show/Hide lines" class="homepage-icon md-primary md-icon-button hide-show-button" @click="toggleFavList">
            <i class="fas fa-eye-slash" v-if="hide"></i>
            <i class="fas fa-eye" v-else></i>
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card id="category-faves">
      <md-card-content ref="cutoff">
        <!-- after fetching lines - getting data -->
        <draggable v-if="lines && lines.length > 0" v-show="!hide" v-model="lines" handle=".editable"
          class="homepage-lines">
          <template v-for='(line, index) in lines'>
            <rec-ads adsType="top" :HasNotAdsSection="HasNotAdsSection" :hasSubscription="hasSubscription"
            v-bind:key="'ads' + line.id" v-if="index === LINES_LIMIT_FOR_TOP_AD">
            </rec-ads>

            <div v-bind:key="index" class="logo-container md-layout"
            :style="{ 'background-color': line.bgcolor }" @click="e => e.target.classList.toggle('active')">

            <div class="md-layout-item md-small-size-100 md-xsmall-size-100 single-logo md-size-10"
              style="display: flex;" :style="isMinCellsSpace ? { 'height': '37px' } : ''">
              <a v-if="line.url" :href="line.url" target="_blank">
                <p v-html="line.name"></p>
              </a>
              <p v-else v-html="line.name"></p>
            </div>
            <div class="md-layout-item md-size-90 md-small-size-100 md-small-size-100"
              style="display: flex; align-items: flex-start">
              <div :class="[makeExpandableLine(!isMinCellsSpace ? line.fullWidthOriginalSpace : line.fullWidthMinSpace) ? 'cutoff-content' : '', 'md-layout'
              , isMinCellsSpace ? 'nospace-cells' : '']">
                <div v-for="(cell) in line.cells" :id="'subcategory-' + cell.id" :key="cell.id"
                  :position="cell.position" :class="[isMinCellsSpace ? 'minTopBottomSpace' : 'originalSpace']"
                  class="md-layout-item single-logo-container" :style="{
                    'max-width': cell.width + 'px',
                    'flex-grow': '0',
                    'display': 'flex',
                    'align-items': 'center',
                    'width': cell.width + 'px',
                    'margin-right': line.space + 'px',
                  }">
                  <a :href="cell.url" :title="cell.alt" target="_blank">
                    <div class="cell-container-faves" :style="{
                      'background-image': 'url(' + line.public_path + ')',
                      'background-position': -(cell.startPosition) + 'px 0',
                      'width': cell.width + 'px',
                      'height': cell.height + 'px', 'background-color': 'white'
                    }">
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </template>
        </draggable>
        <!-- before fetching lines - inital layout -->
        <div class="md-layout md-size-100" v-else-if="lines == null">
          <div class="md-layout-item no-data-container">
            <p>
              <md-progress-bar class="md-accent" md-mode="indeterminate">
                Loading ... </md-progress-bar>
            </p>
          </div>
        </div>
        <!-- after fetching lines - but no data -->
        <div class="md-layout md-size-100" v-else>
          <div class="md-layout-item no-data-container">
            <p>We don't have any data for this page!</p>
          </div>
        </div>
      </md-card-content>
    </md-card>

  </div>
</template>

<script>
import draggable from 'vuedraggable';
import loaderMixin from '@/mixins/loaderMixin';
import adsHelperFunctionsMixin from '@/mixins/adsHelperFunctionsMixin';
import RecAds from '@/components/layout/RecAds.vue';
import { SET_LINES } from '../../store/modules/favPage/favPageActions.type';
import { SET_HIDE_LINES } from '../../store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'CategoryFaves',
  components: {
    draggable,
    RecAds,
  },
  mixins: [
    loaderMixin,
    adsHelperFunctionsMixin,
  ],
  props: {
    categoryName: String,
    year: [String, Number],
  },
  data() {
    return {
      editable: false,
      windowWidth: null,
      isMobile: false,
      publicPath: process.env.BASE_URL,
      isMinCellsSpace: false,
      results: null,
      originalLines: null,
      hide: this.$store.getters.hideLines,
      sortTypes: {
        original: 0,
        asc: 1,
        desc: 2,
      },
      currentSort: 1,
      cuttoffRef: null,
    };
  },
  mounted() {
    this.originalLines = this.$store.getters.favPageUserData.lines;
    this.$nextTick(() => {
      this.cuttoffRef = this.$refs;
    });
  },
  computed: {
    lines() {
      return this.handleWeekLine(this.$store.getters.favPageUserData.lines);
    },
    weeklyLineId() {
      return this.$store.getters.weeklyLine != null ? this.$store.getters.weeklyLine.value : null;
    },
    favPageUserData() {
      return this.$store.getters.favPageUserData;
    },
    showWeeklyLine() {
      return (this.$route.name === 'home' || this.$route.name === 'favesByCountryName' || this.isWeeklyFaves);
    },
    isWeeklyFaves() {
      return this.$route.name === 'weeklyFaves';
    },
  },
  methods: {
    makeExpandableLine(element) {
      if (this.cuttoffRef) {
        const fullLineWidth = this.cuttoffRef.cutoff.$el.clientWidth;
        const lineTitleWidth = 140;
        if ((fullLineWidth - lineTitleWidth) < element) {
          return true;
        }
      }
      return false;
    },
    changeSpacing() {
      this.isMinCellsSpace = !this.isMinCellsSpace;
      if (this.isMinCellsSpace) {
        this.$store.dispatch(SET_LINES, { lines: this.$store.getters.linesWithMinSpace });
      } else {
        this.$store.dispatch(SET_LINES, { lines: this.$store.getters.originalLines });
      }
    },
    toggleFavList() {
      this.hide = !this.hide;
      this.$store.dispatch(SET_HIDE_LINES, this.hide);
    },
    handleWeekLine(lines) {
      const allLines = lines;

      return allLines;
    },
    sortLines() {
      if (this.sortTypes.original === this.currentSort) {
        this.$store.dispatch(SET_LINES, { lines: this.$store.getters.originalLines });
        this.currentSort = this.sortTypes.asc;
      } else if (this.sortTypes.asc === this.currentSort) {
        this.$store.dispatch(SET_LINES, { lines: this.$store.getters.linesWithAscSorting });
        this.currentSort = this.sortTypes.desc;
      } else if (this.sortTypes.desc === this.currentSort) {
        this.$store.dispatch(SET_LINES, { lines: this.$store.getters.linesWithDescSorting });
        this.currentSort = this.sortTypes.original;
      }
    },
  },
};
</script>
<style lang="scss">
.homepage-lines {
  display: inline-block;
  width: 100%;
}

@import '@/assets/scss/layout/favpage.scss';
</style>
