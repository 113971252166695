<template>
  <amf-page id="home-page"
            :hasBreadcrumbs="false"
            :hasSidebar="false">

    <template #content>
      <div class="md-layout md-alignment-center-center">
        <div id="use-top-tabs-card" class="md-layout-item md-size-100">
          <UserTopTabs></UserTopTabs>
        </div>

        <div class="md-layout-item md-size-100">
          <CategoryFaves categoryName="home"></CategoryFaves>
        </div>
      </div>
    </template>
  </amf-page>
</template>

<script>
import Vue from 'vue';
import AmfPage from '@/components/page-templates/AmfPage.vue';
import UserTopTabs from '@/components/common/UserTopTabs.vue';
import CategoryFaves from '@/components/common/CategoryFaves.vue';
import { FETCH_FAV_PAGE, RESET_FAV_PAGE } from '@/store/modules/favPage/favPageActions.type';
import loaderMixin from '@/mixins/loaderMixin';
import { GET_AUTH_USER_TABS } from '../store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'Home',
  components: {
    AmfPage,
    UserTopTabs,
    CategoryFaves,
  },
  mixins: [
    loaderMixin,
  ],
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  mounted() {
    const { hostname } = window.location;
    this.fetchFavPage(hostname);
  },
  methods: {
    fetchFavPage(id) {
      this.$store.dispatch(FETCH_FAV_PAGE, id)
        .then((response) => {
          this.$log.info('FETCH_FAV_PAGE SUCCESS', response);
          Vue.nextTick(() => {
            if (response.data.favPage.title !== null && response.data.favPage.title !== '') {
              document.title = response.data.favPage.title;
            }
          });
        })
        .catch((error) => {
          this.$log.error('FETCH_FAV_PAGE ERROR', error);
        });

      // load user's tabs when load homepage, because maybe new logo are confirmed
      // and replaced while a user was closing his page (in this case pusher not received)
      this.$store.dispatch(GET_AUTH_USER_TABS).then();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(RESET_FAV_PAGE).then(() => {
      next();
    });
  },
};
</script>

<style lang="scss" scoped>
    #home-page {
      .md-layout {
        flex-wrap: wrap !important;
      }
    }

    @import '@/assets/scss/layout/home-page.scss';
</style>
